<template>
  <base-view-layout>
    <template #breadcrumb>
      <li class="breadcrumb__nav-item">{{$t('downloaddata.breadcrumb')}}</li>
    </template>

    <template #title>{{$t('downloaddata.title')}}</template>
    <!-- <template #subtitle>Haz clic en el botón 'DESCARGAR RESPUESTAS' para descargar todas las respuestas{{$store.state.user.role_id === 2 ? ' de tus actividades.' : '.'}}</template> -->
    <template #subtitle>{{$t('downloaddata.subtitle')}}</template>

    <section class="main-section">
      <button class="btn btn--primary" type="button" @click.prevent="downloadData()" :disabled="downloadStarted">{{$t('downloaddata.btndownload')}}</button>
    </section>
    <div>{{$t('downloaddata.tableStatement')}}</div>
    <br><br>
    <div>
      <table class="tableDescription">
        <thead class="headDescription">
          <tr class="rowDescription">
            <td class="cellDescription"><strong>{{$t('downloaddata.column')}}</strong></td>
            <td class="cellDescription"><strong>{{$t('downloaddata.description')}}</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.question')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionQuestion')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.answer')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionAnswer')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.answerType')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionAnswerType')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.dateofanswer')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionDateofanswer')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.activity')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionActivity')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.activityID')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionActivityID')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.eventCode')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionEventCode')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.course')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionCourse')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.axis')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionAxis')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.professorActivity')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionProfessorActivity')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.teacherId')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionTeacherId')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.school1')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionSchool1')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.school2')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionSchool2')}}</td>
          </tr>
          <tr class="rowDescription">
            <td class="cellDescription">{{$t('downloaddata.authorQuestion')}}</td>
            <td class="cellDescription">{{$t('downloaddata.descriptionAuthorQuestion')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </base-view-layout>
</template>

<script>
import { inject } from 'vue'
import BaseViewLayout from '@/components/BaseViewLayout'

export default {
  name: 'DownloadData',
  data () {
    return {
      downloadStarted: false
    }
  },
  components: { BaseViewLayout },
  methods: {
    async downloadData () {
      this.downloadStarted = true
      let { otdt } = (await this.axios.post('auth/one-time-download-token')).data
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = `${this.axios.defaults.baseURL}/answers/download-data?otdt=${otdt}`
      document.body.appendChild(link) // Required for Firefox
      link.click()
      link.remove()
    }
  },
  setup () {
    const axios = inject('$axios')
    return { axios }
  }
}
</script>

<style>
.tableDescription {
  width: 820px;
}

@media only screen and (max-width: 1200px) {
  .tableDescription {
    width: 80%;
  }
}
</style>